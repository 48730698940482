import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import loadable from '@loadable/component'
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { TrimStr } from "../components/Trim";
import Img from "gatsby-image"

const BriefCall = loadable(() => import('../components/BriefCall'));
const ToC = loadable(() => import('../components/ToC'));
const PartnerRoll = loadable(() => import('../components/PartnerRoll'));

export const PartnerPostTemplate = ({
  id,
  content,
  date,
  contentComponent,
  description,
  title,
  www,
  slug,
  headings,
  helmet,
  location,
  featuredimage,
  backgroundimage,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <section>
        {helmet}
        <div id="json"></div>
        {backgroundimage ?
          backgroundimage.childImageSharp ?
        <Img fluid={backgroundimage.childImageSharp.fluid}
         alt={title}
         loading={'eager'}
         title={title}
         style={{height: "350px"}}
         imgStyle={{ height: "350px",
           width: "100%",
           backgroundColor: "black",
           filter: "brightness(0.3)",
           zIndex: '1000'}}
         />
         :
         <img src={backgroundimage}
          alt={title}
          title={title}
          style={{height: "350px",
            width: "100%",
            backgroundColor: "black",
            filter: "brightness(0.3)",
            zIndex: '1000'}}
          /> : <div style={{height: "350px",
             width: "100%",
             backgroundColor: "black",
             filter: "brightness(0.3)",
             zIndex: '1000'}}
           ></div>
        }
        <div
          style={{
            position: "relative",
            textAlign: "center",
            top: "-300px",
            width: "100%",
            display: "block",
            zIndex: '2000'
          }}
        >
          <div style={{ margin: "auto" }}>
          {featuredimage ?
            featuredimage.childImageSharp ?
          <Img fluid={featuredimage.childImageSharp.fluid}
           alt={title}
           title={title}
           style={{height:'150px',overflow:'visible'}}
           imgStyle={{ border: "3px solid black",
           padding: "0px 20px",
           borderRadius: "10px",
           maxWidth:'250px',
           height:'90%',
           objectFit:'contain',
           top:'30px',
           left: 'calc(50% - 125px)',
           backgroundColor: 'white',zIndex:'3000',objectPosition:'center center'}}
           />
           :
           <img src={featuredimage}
            alt={title}
            title={title}
            style={{border: "3px solid black",
            padding: "0px 20px",
            borderRadius: "10px",
            maxWidth:'250px',
            height:'90%',
            objectFit:'contain',
            top:'30px',
            left: 'calc(50% - 125px)',
            backgroundColor: 'white',zIndex:'3000',objectPosition:'center center'}}
            /> : null
           }
            <br />
            <h1
              className="title is-size-5"
              style={{ textAlign: "center", color: "white" }}
            >
              {title}
              <br />
              {/*<span className="ofs">{category}</span>*/}
            </h1>
            <h2
              className="title"
              style={{ textAlign: "center", color: "#36b3d2", fontSize: "1em" }}
            >
              {description}
            </h2>
          </div>
        </div>

        <div className="container content" style={{ marginTop: "-150px" }}>
          {/* Treść */}
          <div>
            <div className="columns">

            </div>

            <div
              className="column is-10 is-offset-1"
              style={{ marginTop: "40px",marginBottom:'40px' }}
            >
              <PostContent content={content} />
            </div>
            </div>
            </div>

            <div style={{textAlign:'center',margin:'50px',position:'relative',display:'block'}}>
            <a style={{margin:'auto'}} target="_blank" rel="noopener noreferrer" className="btnblog" href={www}> Visit website → </a>
            </div>


            <div>











        </div>
      </section>


    </>
  );
};







PartnerPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  www: PropTypes.string,
  helmet: PropTypes.object,
  headings: PropTypes.object,
  id: PropTypes.string
};

const PartnerPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const posts = data.allMarkdownRemark.edges

  let filterPosts = []
  posts.forEach((item, i) => {
    if(item.node.frontmatter.title !== post.frontmatter.title) {
      filterPosts.push(item)
    }
  });

  useEffect(() => {
    if(post.frontmatter.english){
     document.getElementById('lang-switch').href = post.frontmatter.english
   }
   });


  const blogPostingSchemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    url: "https://e-multicontent.com" + post.fields.slug,
    name: post.frontmatter.title.substring(0, 60),
    alternateName: post.frontmatter.title.substring(0, 60) || "",
    headline: post.frontmatter.description.substring(0, 120),
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://e-multicontent.com/partners/" + post.fields.slug
    },
    author: {
      "@type": "Organization",
      name: "e-multiconent team",
      url: "https://e-multicontent.com/about-us/"
    },
    image: {
      "@type": "ImageObject",
      url:
        "https://e-multicontent.com" + post.frontmatter.featuredimage.publicURL
    },
    datePublished: post.frontmatter.date,
    dateModified: post.frontmatter.date,
    publisher: {
      "@type": "Person",
      name: "e-multiconent",
      logo: {
        "@type": "ImageObject",
        url: "https://e-multicontent.com/icons/icon-512x512.png"
      }
    },
    description: 'Our partners - ' + post.frontmatter.description + ' - ' + post.frontmatter.title
  };

  return (
    <Layout>
    <nav className="breadcrumbs-nav section" style={{zIndex:'2300'}}>
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/partners/">Partners</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>{post.frontmatter.title}</b></span>
    </nav>
      <PartnerPostTemplate
        content={post.html.replace(/href/g, "rel='noopener noreferrer' target='_blank' href")}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Partners - e-multiconent">
            <title>
              {`${post.frontmatter.title.substring(0, 60)}`}
            </title>
            <meta
              name="description"
              content={`${'Our partners - ' + post.frontmatter.description + ' - ' + post.frontmatter.title}`}
            />
            <meta
              name="image"
              content={
                "https://e-multicontent.com" +
                post.frontmatter.featuredimage.publicURL
              }
            />

            {/* Schema.org tags */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Partners",
                    item: "https://e-multicontent.com/partners/"
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: post.frontmatter.title,
                    item:
                      "https://e-multicontent.com/partners/" +
                      TrimStr(post.frontmatter.title) +
                      "/"
                  }
                ]
              })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify(blogPostingSchemaOrgJSONLD)}
            </script>

            {/* OpenGraph tags */}
            <meta
              property="og:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              property="og:image"
              content={
                "https://e-multicontent.com" +
                post.frontmatter.featuredimage.publicURL
              }
            />
            <meta
              property="og:url"
              content={"https://e-multicontent.com/partners" + post.fields.slug}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="e-multicontent.com" />
            <meta
              property="twitter:url"
              content={"https://e-multicontent.com/partners/" + post.fields.slug}
            />
            <meta
              name="twitter:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta
              name="twitter:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              name="twitter:image"
              content={
                "https://e-multicontent.com" +
                post.frontmatter.featuredimage.publicURL
              }
            />
            <link rel="alternate" href={post.frontmatter.english} hrefLang="pl-pl" />
            <link rel="canonical" href={"https://e-multicontent.com" + post.fields.slug}  />
          </Helmet>
        }
        title={post.frontmatter.title}
        www={post.frontmatter.www}
        featuredimage={post.frontmatter.featuredimage}
        backgroundimage={post.frontmatter.backgroundimage}
        posts={filterPosts}
        headings={<ToC headings={post.headings}
        />
        }
      />
      <div className="container content">


      <div
        className="column is-10 is-offset-1"
        style={{ marginTop: "50px" }}
      >
      <p className="title">See other partners:</p>

      <PartnerRoll posts={posts} />



      </div>


    </div>
      <div className="container content is-offset-1" style={{textAlign:'center',marginTop:'50px',marginBottom:'50px'}}>


        <Link
          className="btn"
          style={{ fontSize: "1.1em" }}
          to="/portfolio/"
        >
          Portfolio &nbsp; &nbsp;
          <img
            loading="lazy"
            width="15px" height="22px"
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            alt="brief"
            src="/img/angle-right.svg"
          />
        </Link>
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        <Link
          className="btn"
          style={{ fontSize: "1.1em" }}
          to="/"
        >
          Home &nbsp; &nbsp;
          <img
            loading="lazy"
            width="15px" height="22px"
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            alt="brief"
            src="/img/angle-right.svg"
          />
        </Link>
        &nbsp; &nbsp;
        &nbsp; &nbsp;
        <Link
          className="btn"
          style={{ fontSize: "1.1em" }}
          to="/contact-us/"
        >
          Contact us &nbsp; &nbsp;
          <img
            loading="lazy"
            width="15px"
            style={{ verticalAlign: "middle", marginRight: "5px" }}
            alt="brief"
            src="/img/angle-right.svg"
          />
        </Link>

      </div>
      <BriefCall />
    </Layout>
  );
};

PartnerPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  location: PropTypes.shape({}).isRequired
};

export default PartnerPost;

export const pageQuery = graphql`
  query PartnerPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      headings {
        value
        depth
      }
      html
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        www
        description
        english
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "partner-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            industry
            templateKey
            date(formatString: "DD. MM. YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            backgroundimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            tags
          }
        }
      }
    }
  }
`;
